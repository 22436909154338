<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <v-alert
            border="left"
            type="info"
            elevation="2"
            icon="mdi-account-multiple"
            class="mx-auto"
          >
            <div class="text-h5 pe-0 pe-sm-10">Ficha de Encaminhamento Escolar</div>
            <div class="text-h6">{{ matricula.aluno?.nomecompleto }}</div>
          </v-alert>
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12">
                    <e-label>Objetivo(s) do encaminhamento</e-label>
                    <ValidationProvider name="Objetivos" v-slot="{ errors }" rules="required">
                      <v-select
                        v-model="dados.objetivos"
                        :items="['Psicopedagogia', 'Serviço Social (CRAS)', 'Conselho Tutelar']"
                        chips
                        label="Objetivos"
                        multiple
                        :error-messages="errors"
                        solo
                        dense
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <e-label>Telefone do responsável</e-label>
                    <ValidationProvider
                      name="Telefone do responsável"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        v-model="dados.telefone_responsavel"
                        chips
                        label="Telefone do responsável"
                        v-mask="'(##) # ####-####'"
                        :error-messages="errors"
                        solo
                        dense
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <e-label>Professor solicitante</e-label>
                    <ValidationProvider
                      name="Professor solicitante"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-select
                        v-model="dados.instrutor_id"
                        :items="professoresList"
                        chips
                        item-value="id"
                        item-text="nome"
                        label="Professor solicitante"
                        :error-messages="errors"
                        solo
                        dense
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <e-label>Justificativa/Queixa</e-label>
                    <ValidationProvider
                      name="Justificativa/Queixa"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-textarea
                        v-model="dados.justificativa"
                        chips
                        hint="Justificativa/Queixa"
                        :error-messages="errors"
                        solo
                        dense
                      ></v-textarea>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="$emit('updateModal', false)"> Fechar </v-btn>
              <v-btn
                color="green darken-1"
                text
                :disabled="submittingForm"
                :loading="submittingForm"
                type="submit"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    matricula: {
      type: Object,
      default: () => {},
    },
    professoresList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogLocal: false,
      dados: {},
      submittingForm: false,
    };
  },
  methods: {
    async submitForm() {
      this.submittingForm = true;
      this.$loaderService.open("Baixando ficha de Encaminhamento Escolar");
      try {
        await this.$services.relatoriosService.fichaDeMatriculaEncaminhamentoEscolar(
          this.matricula.id,
          this.dados
        );
        this.dados = {};
        this.$emit("updateModal", false);
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.submittingForm = false;
        this.$loaderService.close();
      }
    },
  },
};
</script>
